import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store'; // Import RootState from your store configuration


export interface Email {
  uid: string;
  text: string;
  to: string;
  subject?: string;
  cc?: string | null;
  bcc?: string | null;
  thread_id?: number;
}

const emailAdapter = createEntityAdapter({
  selectId: (email: Email) => email.uid,
  sortComparer: (a, b) => a.uid.localeCompare(b.uid)
});

const initialState = emailAdapter.getInitialState();

const emailsSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    setEmails: emailAdapter.setAll,
    upsertEmail: emailAdapter.upsertOne
  },
});

export const { setEmails, upsertEmail } = emailsSlice.actions;
export default emailsSlice.reducer;


export const {
  selectAll: selectAllEmails,
  selectById: selectEmailById,
} = emailAdapter.getSelectors((state: RootState) => state.emails);
