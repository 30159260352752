import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store'; // Import RootState from your store configuration
import {Payment} from './paymentSlice';


export interface Client {
  customer_organization_id: number;
  name?: string;
  num_invoices: number;
  balance: string;
}

const clientAdapter = createEntityAdapter({
  selectId: (client: Client) => client.customer_organization_id,
  sortComparer: (a, b) => a.customer_organization_id - b.customer_organization_id,
});

const initialState = clientAdapter.getInitialState();

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients: clientAdapter.setAll,
    upsertClient: clientAdapter.upsertOne,
    updateClients: (state, action) => {
      const payments = action.payload as Payment[];
      const acc = {};
      payments.forEach(payment => {
        const { customer_organization_id, amount, currency_type, amountUSD } = payment;
        if (!acc[customer_organization_id]) {
          acc[customer_organization_id] = { balance_usd: 0, num_invoices: 0 };
        }
        acc[customer_organization_id].balance_usd += amountUSD;
        acc[customer_organization_id].num_invoices += 1;
      });

      const clientsArr: Client[] = Object.entries(acc).map(([customer_organization_id, data]) => ({
        customer_organization_id: parseInt(customer_organization_id, 10),
        num_invoices: data.num_invoices,
        balance: '$ ' + data.balance_usd.toLocaleString(undefined, {maximumFractionDigits: 1}),
        name: '', 
      }));
      clientAdapter.setAll(state, clientsArr);
    }
  },
});

export const { setClients, upsertClient, updateClients } = clientsSlice.actions;
export default clientsSlice.reducer;


export const {
  selectAll: selectAllClients,
  selectById: selectClientById,
} = clientAdapter.getSelectors((state: RootState) => state.clients);
