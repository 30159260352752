import { TextInput as MTextInput } from "@mantine/core"
import { Icon } from "@phosphor-icons/react"

export interface TextInputProps {
  /**  Optional icon on the left of the text input */
  Icon?: Icon | null
  /** Optional placeholder text for the input */
  placeholder?: string | null
  /**  Styling variant for the input */
  style?: "filled" | "outline" | "subtle"
  /** Function to trigger when the input changes */
  onChange?: () => void
  /** Function to trigger when the user hits enter */
  onEnter?: () => void
}

/**  Text Input Component */
export const TextInput = ({
  style = "filled",
  Icon = null,
  placeholder = null,
  onChange = () => {},
  onEnter = () => {},
}: TextInputProps) => {
  let styleClasses =
    "flex border rounded-lg text-sm w-auto focus-within:shadow-md hover:shadow-md transition transform "
  let iconColor = ""
  let placeholderColor = ""
  switch (style) {
    case "filled":
      styleClasses += "border-lavender-300 bg-lavender-50"
      iconColor = "text-lavender-900/60"
      placeholderColor =
        "placeholder:text-lavender-900/60 text-lavender-600 caret-lavender-600"
      break
    case "outline":
      styleClasses += "border-midnight-900 text-midnight-400"
      iconColor = "text-midnight-900"
      placeholderColor =
        "placeholder:text-neutral-400 text-midnight-900 caret-midnight-900"
      break
    case "subtle":
      styleClasses += "border-neutral-200 text-neutral-400"
      iconColor = "text-neutral-300"
      placeholderColor =
        "placeholder:text-neutral-300 text-neutral-500 caret-neutral-500"
      break
  }
  if (!Icon) {
    styleClasses += " px-2"
  } else {
    styleClasses += " pr-2"
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnter()
    }
  }

  const icon = Icon ? (
    <Icon weight="light" size={20} className={iconColor} />
  ) : null
  return (
    <MTextInput
      leftSectionPointerEvents="none"
      leftSection={icon}
      placeholder={placeholder}
      variant="unstyled"
      classNames={{
        wrapper: "w-full",
        input: placeholderColor,
        root: styleClasses,
      }}
      onChange={onChange}
      onKeyDown={handleKeyDown}
    />
  )
}
