import { useDisclosure } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import {startedUpdating, selectLastUpdateTime, selectIsCurrentlyUpdating, selectUnpaidInvoices, selectOpenBalance, selectOverdue,
    selectApprovalsAmountAndCount, selectUnresolvedAmountAndCount, selectNoReplyAmountAndCount, selectNeedsAttentionAmountAndCount
  } from "../store/slices/generalDataSlice";
  import { ActionIcon, Button, Center, Menu, Loader, Modal, ThemeIcon, Dialog, Group} from '@mantine/core';
  import React from "react"
  import {useState, useMemo, useEffect} from "react"
  import { Text } from "@mantine/core"
  import { TextInput } from "./TextInput"
  import { DatePicker } from "./DatePicker"
  import { MultiSelect } from "./MultiSelect"
  import { Card } from "./Card"
  import { PaginatedTable } from "./PaginatedTable"
  import { MinimalCardList } from "./MinimalCard"
  import {MagnifyingGlass, Receipt,  Money,  Clock,  Alarm,  SealCheck,  Envelope,  Question,  WarningCircle} from "@phosphor-icons/react"
  import apiEndpoints, { useLazyGetAllOpenPaymentsQuery, useLazyGetEmailDraftForAllOpenPaymentsQuery } from "../store/slices/apiSliceEndpoints"
  import { IconAlertCircle, IconCheck, IconClockQuestion, IconHandFinger, IconMail, IconRotateClockwise, IconSend } from '@tabler/icons-react';
  import { RootState } from '../store/store';
  import { selectAllPayments } from '../store/slices/paymentSlice';
  import { useDispatch, useSelector } from 'react-redux';


  let minimalCardData = [
    {
      heading: "Unpaid Invoices",
      description: "?",
      Icon: Receipt,
      key:1,
    },
    {
      heading: "Open Balance",
      description: "?",
      Icon: Money,
      key:2,
    },
    {
      heading: "Overdue",
      description: "?",
      Icon: Alarm,
      key:3,
    },
    // {
    //   heading: "Avg. Invoice to Cash",
    //   description: "?",
    //   Icon: Clock,
    //   key:4,
    // },
  ]

  let cardData = [
    {
      heading: "?",
      description: "Approvals",
      subText: "",
      color: "lime",
      Icon: IconCheck,
      id:1,
    },
    {
      heading: "?",
      description: "Unresolved",
      subText: "",
      color: "yellow",
      Icon: IconHandFinger,
      id:2,
    },
    {
      heading: "?",
      description: "No Reply",
      subText: "",
      color: "apricot",
      Icon: IconClockQuestion,
      id:3,
    },
    {
      heading: "?",
      description: "Needs Attention",
      subText: "",
      color: "red",
      Icon: IconAlertCircle,
      id:4,
    },
  ]


  const Home = () => {
    const dispatch = useDispatch();
    const [getPayments] = useLazyGetAllOpenPaymentsQuery();
    const [getEmails, resultsGetEmails] = useLazyGetEmailDraftForAllOpenPaymentsQuery();
    const allPayments = useSelector((state: RootState) => selectAllPayments(state));
    const lastUpdateTime = useSelector(selectLastUpdateTime);
    const isCurrentlyUpdating = useSelector(selectIsCurrentlyUpdating);
    const unpaidInvoices = useSelector(selectUnpaidInvoices);
    const openBalance = useSelector(selectOpenBalance);
    const overdue = useSelector(selectOverdue);
    const approvalsAmountAndCount = useSelector(selectApprovalsAmountAndCount);
    const unresolvedAmountAndCount = useSelector(selectUnresolvedAmountAndCount);
    const noReplyAmountAndCount = useSelector(selectNoReplyAmountAndCount);
    const needsAttentionAmountAndCount = useSelector(selectNeedsAttentionAmountAndCount);
    const [selectedIntentionCardId, setSelectedIntentionCardId] = useState(null);
    const [searchText, setSearchText] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const [openedEmailNotifier, { toggle: openEmailNotifier, close: closeEmailNotifier }] = useDisclosure(true);
    
    const emailStatus = location.state?.emailStatus;
    // useEffect(() => {
    //   if (emailStatus) {
    //     // Clear the state after reading it
    //     navigate(location.pathname, { replace: true, state: {} });
    //   }
    // }, [emailStatus, location.pathname, navigate]);
    

    if (unpaidInvoices) {
      minimalCardData[0].description = unpaidInvoices.toString();
    }
    if (openBalance) {
      minimalCardData[1].description = openBalance.toString();
    }
    if (overdue) {
      minimalCardData[2].description = overdue.toString();
    }
    if (approvalsAmountAndCount) {
      cardData[0].heading = approvalsAmountAndCount[0]
      cardData[0].subText = approvalsAmountAndCount[1]
    }
    if (unresolvedAmountAndCount) {
      cardData[1].heading = unresolvedAmountAndCount[0]
      cardData[1].subText = unresolvedAmountAndCount[1]
    }
    if (noReplyAmountAndCount) {
      cardData[2].heading = noReplyAmountAndCount[0]
      cardData[2].subText = noReplyAmountAndCount[1]
    }
    if (needsAttentionAmountAndCount) {
      cardData[3].heading = needsAttentionAmountAndCount[0]
      cardData[3].subText = needsAttentionAmountAndCount[1]
    }

    const updateData = async () => {
      dispatch(startedUpdating());
      getEmails();
      await getPayments();
      // Get emails again after payments have finished loading, since some new emails may only be available after getPayments finished running (on the server).
      getEmails();
    }

    if (isCurrentlyUpdating === null) {
      // This triggers updateData once (isCurrentlyUpdating will be false or true after this).
      updateData();
    }

    const handleSearchChange = (event) => {
      setSearchText(event.target.value);
    };
    
  
    // Take the received payment data, process the relevant columns, ignore the ones that aren't displayed.
    const processedInvoices = allPayments.map(obj => {
      const newObj = {};
      const wantedKeys = ['external_id', 'customer_organization_name', 'amount', 'due_date', 'currency_type', 'intention_status', 'new_msg', 'daysUntilDueDate', 'daysSinceLastReceived'];
      for (const key in obj) {
        if (wantedKeys.includes(key)) {
          if (key == 'amount') {
            newObj[key] = obj[key].toLocaleString(undefined, {maximumFractionDigits: 1});
          } else if (key == 'due_date' && obj[key]) {
            newObj[key] = obj[key].split('T')[0];
          } else {
            newObj[key] = obj[key];
          }
        } else {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    });

    return (
      <>    
      <div className="flex flex-col">
        {/* <div className="bg-lavender-50 w-full h-1/4 right-0 top-0 absolute -z-10 border-4"></div> */}
        <div className="flex flex-col h-full w-full justify-end gap-8 pt-2 pr-12 pl-2">
          <div className="flex flex-row items-center w-full gap-4">
            <div className="flex flex-row">
              <TextInput
              
                Icon={MagnifyingGlass}
                placeholder="search"
                onChange={handleSearchChange}
                onEnter={() => null}/>
            </div>
            {/* <DatePicker />
            <MultiSelect /> */}
            <Text className='text-m ml-9' c='gray'>Last updated: {lastUpdateTime}</Text>
            {isCurrentlyUpdating &&
              <Loader size={20} color="blue" />
            }
            {!isCurrentlyUpdating &&
              <ActionIcon variant="default" aria-label="Settings" color='black' onClick={() => updateData()}>
                <IconRotateClockwise style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            }

          </div>
          {emailStatus == 'email sent successfully'?
            <Dialog opened={openedEmailNotifier} withCloseButton onClose={closeEmailNotifier} size="lg" radius="md">
              <Button variant="white" radius="md" color='blue' mx='20' pl='10' size={"l"} leftSection={<IconSend/>}>Email sent successfully</Button> 
            </Dialog>
            : <> </>
          }
          <div className="flex flex-col items-start justify-start gap-4 w-full">
            
            <MinimalCardList cardProps={minimalCardData} />
          </div>
          <div className="flex flex-col items-start justify-start gap-4 w-full">
            <Text className="text-midnight-900 font-medium text-xl">
              Email Status
            </Text>
            <div className="flex flex-row items-center justify-between w-full">
              {cardData.map((data) => (
                <Card
                  key={data.id}
                  heading={data.heading}
                  description={data.description}
                  subText={data.subText}
                  Icon={data.Icon}
                  color={(data.color) as "lime" | "yellow" | "apricot" | "aqua" | "lavender" | "orange" | "red"}
                  onClickFunc={() => {
                    if (selectedIntentionCardId == data.id) {
                      setSelectedIntentionCardId(null);
                    } else {
                      setSelectedIntentionCardId(data.id)
                    }
                  }}
                  isSelected = {selectedIntentionCardId == data.id}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-2 w-full">
            <Text className="text-midnight-900 font-medium text-xl">Unpaid invoices</Text>
            <div className='w-full'>
                <PaginatedTable invoices={processedInvoices} pageSize={12} perPaymentKey="id" selectedIntentionCardId={selectedIntentionCardId} searchText={searchText}/>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  
  export default Home;

