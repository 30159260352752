import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store'; // Import RootState from your store configuration
import { updateEmail } from '@firebase/auth';
import {Payment} from './paymentSlice';


export interface GeneralData {
  lastUpdateTime?: string | null
  isCurrentlyUpdating?: boolean | null
  unpaidInvoices?: string | null
  openBalance?: string | null
  overdue?: string | null
  avgDaysInvoiceToCash: number | null
  approvalsAmountAndCount: string[] | null
  unresolvedAmountAndCount: string[] | null
  noReplyAmountAndCount: string[] | null
  needsAttentionAmountAndCount: string[] | null
}

const initialState: GeneralData = {
  lastUpdateTime: null,
  isCurrentlyUpdating: null,
  unpaidInvoices: null,
  openBalance: null,
  overdue: null,
  avgDaysInvoiceToCash: null,
  approvalsAmountAndCount: null,
  unresolvedAmountAndCount: null,
  noReplyAmountAndCount: null,
  needsAttentionAmountAndCount: null,

}

const generalDataSlice = createSlice({
  name: 'generalData',
  initialState,
  reducers: {
    startedUpdating: (state) => {
      state.isCurrentlyUpdating = true
    },
    finishedUpdating: (state, action) => {
      const { updateTime } = action.payload
      state.isCurrentlyUpdating = false
      state.lastUpdateTime = updateTime
    },
    updateInvoiceStatsBar: (state, action) => {
      const payments = action.payload as Payment[];
      const unpaidInvoicesNew = payments.length;
      let openBalanceNew = 0;
      let overdueNew = 0;
      // let avgDaysInvoiceToCashNew = [0, 0]; // days invoice to cash, counter
      const currentDate = new Date();
      for (const payment of payments) {
        const dueDate = new Date(payment.due_date);
        const isPast = dueDate < currentDate;
        const convertedAmount = payment.amountUSD
        openBalanceNew += convertedAmount
        if (isPast) {
          overdueNew += convertedAmount
        }
      }
      state.unpaidInvoices = `${unpaidInvoicesNew.toLocaleString(undefined, {maximumFractionDigits: 1})}`;
      state.openBalance = `$${openBalanceNew.toLocaleString(undefined, {maximumFractionDigits: 1})}`;
      state.overdue = `$${overdueNew.toLocaleString(undefined, {maximumFractionDigits: 1})}`;
    },
    updateIntentionStatusBar: (state, action) => {
      const payments = action.payload as Payment[];
      // usd, count
      let approvals = [0, 0];
      let unresolved = [0, 0];
      let noReply = [0, 0];
      let needsAttention = [0, 0];
      for (const payment of payments) {
        let intentionStatus = null;
        if (payment.intention_status == 'Approved') {
          intentionStatus = approvals;
        } else if (payment.intention_status == 'Unresolved') {
          intentionStatus = unresolved;
        } else if (payment.intention_status == 'No_reply') {
          intentionStatus = noReply;
        } else if (payment.intention_status == 'Needs_attention') {
          intentionStatus = needsAttention;
        }
        intentionStatus[0] += payment.amountUSD
        intentionStatus[1] += 1
      }
      const approvalsAmount = `$${approvals[0].toLocaleString(undefined, {maximumFractionDigits: 1})}`;
      state.approvalsAmountAndCount = [approvalsAmount, approvals[1].toString() + ' Invoices']
      const unresolvedAmount = `$${unresolved[0].toLocaleString(undefined, {maximumFractionDigits: 1})}`;
      state.unresolvedAmountAndCount = [unresolvedAmount, unresolved[1].toString() + ' Invoices']
      const noReplyAmount = `$${noReply[0].toLocaleString(undefined, {maximumFractionDigits: 1})}`;
      state.noReplyAmountAndCount = [noReplyAmount, noReply[1].toString() + ' Invoices']
      const needsAttentionAmount = `$${needsAttention[0].toLocaleString(undefined, {maximumFractionDigits: 1})}`;
      state.needsAttentionAmountAndCount = [needsAttentionAmount, needsAttention[1].toString() + ' Invoices']
    }
  },
});

export default generalDataSlice.reducer;
export const { startedUpdating, finishedUpdating, updateInvoiceStatsBar, updateIntentionStatusBar } = generalDataSlice.actions;
export const selectLastUpdateTime = (state: {generalData: { lastUpdateTime: string | null }
}) => state.generalData.lastUpdateTime
export const selectIsCurrentlyUpdating = (state: { generalData: { isCurrentlyUpdating: boolean | null } }) =>
  state.generalData.isCurrentlyUpdating
export const selectUnpaidInvoices = (state: { generalData: { unpaidInvoices: number | null } }) =>
  state.generalData.unpaidInvoices
export const selectOpenBalance = (state: { generalData: { openBalance: string | null } }) =>
  state.generalData.openBalance
export const selectOverdue = (state: { generalData: { overdue: string | null } }) =>
  state.generalData.overdue
export const selectApprovalsAmountAndCount = (state: { generalData: { approvalsAmountAndCount: string[] | null } }) =>
  state.generalData.approvalsAmountAndCount
export const selectUnresolvedAmountAndCount = (state: { generalData: { unresolvedAmountAndCount: string[] | null } }) =>
  state.generalData.unresolvedAmountAndCount
export const selectNoReplyAmountAndCount = (state: { generalData: { noReplyAmountAndCount: string[] | null } }) =>
  state.generalData.noReplyAmountAndCount
export const selectNeedsAttentionAmountAndCount = (state: { generalData: { needsAttentionAmountAndCount: string[] | null } }) =>
  state.generalData.needsAttentionAmountAndCount
