import { useState, useEffect, useMemo } from "react"
import { Pagination, NativeSelect, Text } from "@mantine/core"
import { Payment } from "../store/slices/paymentSlice"
import { Table } from "./Table"

export interface PaginatedTableProps {
  invoices: object[]
  pageSize: number
  perPaymentKey: string
  selectedIntentionCardId: number
  searchText: string
}

export function PaginatedTable({ invoices, pageSize, perPaymentKey, selectedIntentionCardId, searchText  }: PaginatedTableProps) {

  // The following two hooks store and retrieve the activePage from localStorage, so that it persists 
  // in between navigations (i.e, if you select an individual payment and then return, you'll see the 
  // same page, rather than the 1st). If instead we were to use a naive useState(1) call, since the component
  // rerenders anew each time we navigate back to it, it would start from the 1st page every time.
  const [activePage, setActivePage] = useState(
    () => parseInt(localStorage.getItem('activePage')|| '1') // Check for existing or set to 1
  );
  useEffect(() => {
    localStorage.setItem('activePage', activePage.toString());
  }, [activePage]);


  const filteredInvoices = useMemo(() => {    
    if (!selectedIntentionCardId && !searchText) return invoices;
    let filteredInvoicesTemp = invoices;
    if (selectedIntentionCardId) {
      const cardKeyMapping = {
        1: 'Approved',
        2: 'Unresolved', 
        3: 'No_reply',
        4: 'Needs_attention',
      };
      const filterKey = cardKeyMapping[selectedIntentionCardId];
      filteredInvoicesTemp = invoices.filter(invoice => invoice['intention_status'] == filterKey);
    }
    if (searchText) {
      // Disregard commas as well as distinction btwn upper/lower case, since commas (in numbers) are added programmatically to the UI,
      // but aren't present in the underlying data.
      const normalizedSearchTerm = searchText.toLowerCase().replace(/,/g, '');
      filteredInvoicesTemp = filteredInvoicesTemp.filter(invoice => {
        return Object.values(invoice).some(value => {
          const normalizedValue = (value || '').toString().toLowerCase().replace(/,/g, '');
          return normalizedValue.includes(normalizedSearchTerm);
        });
      });
    }
    // In case the filtering happens when the user was on page (e.g) 7, take him back to page #1, 
    // since after filtering there might not be enough entries left for page 7 to display anything.
    setActivePage(1);
    return filteredInvoicesTemp;
  }, [invoices, selectedIntentionCardId, searchText]);


  const numPages = Math.ceil(filteredInvoices.length / pageSize)
  let items = filteredInvoices.slice((activePage - 1) * pageSize, activePage * pageSize)
  useEffect(() => {
    items = filteredInvoices.slice((activePage - 1) * pageSize, activePage * pageSize)
  }, [activePage])
  
  return (
    <div className="flex flex-col gap-1">
      <Table data={items} perPaymentKey={perPaymentKey} />
      <div className="flex flex-row items-end justify-between">
        <Pagination
          total={numPages}
          value={activePage}
          onChange={setActivePage}
          mt="sm"
          classNames={{
            control:
              "data-[active]:bg-lavender-100 rounded-full text-midnight-900 text-sm disabled:bg-neutral-50 disabled:text-neutral-400 transition transform",
          }}
        />
        {/* Start of an implemenation for a selector to choose rows per page */}
        {/* <div className="flex flex-row items-center text-midnight-900 gap-2"> */}
        {/*   <NativeSelect data ={ [{value: "10", label: "10"}, {value: "20", label: "20"}]} classNames={ {wrapper: "w-16 h-8", input: "text-midnight-900 text-xs"}}/> */}
        {/*   <Text className="text-midnight-900 text-xs">Rows Per Page</Text> */}
        {/* </div> */}
      </div>
    </div>
  )
}
