import {startedUpdating, finishedUpdating, updateInvoiceStatsBar, updateIntentionStatusBar} from "./generalDataSlice";
import { apiSlice } from "../baseQueries"
import { Payment, selectAllPayments, setPayments } from "./paymentSlice"
import { Client, setClients, updateClients } from "./clientSlice"
import { Email, setEmails, upsertEmail } from "./emailSlice"
import {store} from "../store"

const paymentsPrefix = "/payments";

// TODO: This should be dynamic!
export const conversionRatioForeignToUSD = {'EUR': 1.09, 'NIS': 0.26, 'USD': 1}
const unixEpoch = new Date("1970-01-01T00:00:00");

function refactorPayments(payments: Payment[]) {
  const currentDate = new Date();
  for (const payment of payments) {
    const convertedAmount = conversionRatioForeignToUSD[payment.currency_type] * payment.amount;
    payment.amountUSD = convertedAmount;
    const dueDate = new Date(payment.due_date);
    const differenceInTimeDueDate = currentDate.getTime() - dueDate.getTime();
    const differenceInDaysDueDate = Math.round(differenceInTimeDueDate / (1000 * 3600 * 24));
    payment.daysUntilDueDate = differenceInDaysDueDate;
    
    const lastReceivedMessage = new Date(payment.time_last_received_message);
    if (lastReceivedMessage.getTime() === unixEpoch.getTime()) {
      payment.daysSinceLastReceived = Infinity;
    } else {
      const differenceInTimeLastReceived = currentDate.getTime() - lastReceivedMessage.getTime();
      const differenceInDaysLastReceived = Math.round(differenceInTimeLastReceived / (1000 * 3600 * 24));
      payment.daysSinceLastReceived = differenceInDaysLastReceived;
    }
  }
  return payments;
}


const apiEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentById: builder.query<Payment, string>({
      query: (id) => `${paymentsPrefix}/${id}`,
      providesTags: (result, error) =>
        result ? [{ type: 'Payment', id: result.id }] : [],
    }),
    getAllOpenPayments: builder.query<[Payment[], dict:Record<string, any> ], void>({
      query: () => `${paymentsPrefix}/get_all_open_payments`,
      providesTags: (result) => {
        if (result) {
          return [{type: 'Payment', id:'List'}, ...result[0].map((payment) => ({ type: 'Payment' as const, id: payment.id }))]
        }
        return [{type: 'Payment', id:'List'}]
      },
      transformResponse: (response: [Payment[], dict:Record<string, any>]) => {
        const convertedPayments = refactorPayments(response[0]);
        store.dispatch(setPayments(convertedPayments));
        const lastE2eRefreshEnd = response[1]['last_e2e_refresh_end']
        store.dispatch(updateInvoiceStatsBar(convertedPayments));
        store.dispatch(updateIntentionStatusBar(convertedPayments));
        store.dispatch(updateClients(convertedPayments));
        store.dispatch(finishedUpdating({updateTime:lastE2eRefreshEnd}))
        return response;
      },
    }),
    getEmailDraftForPayment: builder.query<{ msg: string, status: string }, number>({
      query: (draftUrl) =>  ({
        url: `${paymentsPrefix}/get_email_draft_for_payment`,
        params: { draft_url: draftUrl },
      }),
      providesTags: (result, error, draftUrl) =>
        result ? [{ type: 'Email', id: draftUrl }] : [],
      transformResponse: (response: { msg: string, status: string }) => {
        if (response.status != 'no message draft') {
          const parsedMsg = JSON.parse(response.msg) as Email;
          store.dispatch(upsertEmail(parsedMsg));
        }
        return response;
      },
    }),
    getEmailDraftForAllOpenPayments: builder.query<{ msgs: [string], status: string }, void>({
      query: () =>  ({
        url: `${paymentsPrefix}/get_email_draft_for_all_open_payments`
      }),
      providesTags: (result) => {
        if (result) {
          return [{type: 'Email', id:'List'}, ...result['msgs'].map((msg) => ({ type: 'Email' as const, id: JSON.parse(msg).uid }))]
        }
        return [{type: 'Email', id:'List'}]
      },
      transformResponse: (response: { msgs: [string], status: string }) => {
        if (response.status == 'has message draft') {
          const parsedMsgs = response.msgs.map(msg => JSON.parse(msg) as Email);
          store.dispatch(setEmails(parsedMsgs));
        }
        return response;
      },
    }),
    sendEmail: builder.mutation({
      query: ({emailData, draftUrl, attachments}) => {
        const email_data = new FormData();
        email_data.append('text', emailData.text);
        email_data.append('subject', emailData.subject);
        email_data.append('to', emailData.to);
        email_data.append('cc', emailData.cc || '');
        email_data.append('bcc', emailData.bcc || '');
        email_data.append('thread_id', emailData.threadId || '');
        email_data.append('message_draft_url', draftUrl);
        if (attachments.length == 0) {
          //  Check if the attachment is fake. I (Yoni Stoller) couldn't find how to get FastAPI to allow you to *optionally* upload a list of files,
          //  so the frontend has to send a fake file if there are no real attachments/files, so that there's always at least one attachment.
          const fakeFile = new File([""], "empty.txt", { type: "text/plain" });
          attachments = [fakeFile];
        }
        attachments.forEach((file, index) => {
          email_data.append('attachments', file);
        });

        return {
          url: `${paymentsPrefix}/send_email`,
          method: "POST",
          body:email_data,
          formData:true,
        }
      },
      invalidatesTags: (result: any, error: any, { draftUrl }) => {
        console.log('invalidates tags', result)
        if (result == 'success') { // Only invalidate if the mutation was successful
          const payments = selectAllPayments(store.getState());
          // Find payments that have the matching message_draft_url
          const matchingPayments = payments.filter(payment => payment.message_draft_url == draftUrl);
          const invalidationPaymentTags = matchingPayments.map(payment => ({ type: 'Payment' as const, id: payment.id }));
          console.log('id to drop', draftUrl, invalidationPaymentTags)
          return [
            { type: 'Email', id: draftUrl },
            ...invalidationPaymentTags
          ];
        }
        // Return an empty array if the mutation failed, so no tags are invalidated
        return [];
      },
    }),
  }),
})


export const {
  useLazyGetAllOpenPaymentsQuery,
  useGetPaymentByIdQuery,
  // useLazyTriggerRefreshPaymentsQuery,
  useGetEmailDraftForPaymentQuery,
  useLazyGetEmailDraftForAllOpenPaymentsQuery,
  useSendEmailMutation,
} = apiEndpoints

export default apiEndpoints
